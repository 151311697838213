/**
JD SENSE Add. 2019.01.18
 */

  /*.progress-bar-danger {background-color: #908c8c !important}*/
.t_select_lng{
    border: 1px solid #0c91e5;
    background-color: #0c91e5;
    padding: 4px 10px 5px 10px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    margin: 0 0 0 0;
}

.t_select_lng:hover{
    border: 1px solid #0b82ce;
    background-color: #0b82ce;
    padding: 4px 10px 5px 10px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    margin: 0 0 0 0;
}

/*** TreeGrid ***/
.TSMain,.TSMain *, .GridMain1.GridMain2.TSMain * {font-size:12px !important; line-height:16px !important;
    font-family: 'NanumGothic', Muli, Roboto, Helvetica Neue, Arial, sans-serif;
}
.TSSpaceWidthInner, .TSCellSpaceBase .TSSpaceWidthInner span {visibility: hidden}
.TSType{padding: 4px 8px 4px 8px}

/*** text align ***/
.left {text-align: left !important}
.center {text-align: center !important}
.right {text-align: right !important}
.top {vertical-align: top !important}
.middle {vertical-align: middle !important}
.bottom {vertical-align: bottom !important}

/*** button ***/
.btn_ins {background-color: #ea3d31; color: #fff; opacity: 0.9 !important}
.btn_ins:hover{background-color: #ea3d31; color: #fff; opacity: 1.0 !important}
.bottom_sw {width: 100%; position: fixed; left:0; bottom: 0; z-index: 999; text-align: center; font-weight: bold; font-size: 14px; padding: 15px 10px; background-color: #efefef}
.bottom_sw:active {width: 100%; position: fixed; left:0; bottom: 0; z-index: 999; text-align: center; color: #fff; font-weight: bold; font-size: 14px; padding: 15px 10px; background-color: #337ab7}
.btn_print {background-color: #ea3d31; color: #fff; opacity: 0.9 !important}
.save_button {border: 1px solid #0074bb; height: 85px; color: #0074bb; }

/*** float ***/
.f_l{float: left !important}
.f_r{float: right !important}
.clear{clear: both !important}

/*** border-radius ***/
.bdr10 {border-radius: 10px !important; border: 7px solid #e0e0e0}

/*** cursor ***/
.cp{cursor: pointer !important}

/*** width ***/
.w70 {width: 70% !important}
.w80 {width: 80% !important}
.w90 {width: 90% !important}
.w100 {width: 100% !important}
.w150px {width: 150px !important}
.w180px {width: 180px !important}
.w200px {width: 200px !important}
.mw30px{min-width: 30px !important}


/*** height ***/
.h-64 {height: 64px !important}
.h-40 {height: 40px !important}

/*** block ***/
.ds_block{display: block !important}

/*** background ***/
.bg_black {background-color:  #333 !important}
.bg_black:hover {background-color:  #555555 !important}
.bg_black_g {background-color:  #111 !important}
.bg_black_g2 {background-color:  #333 !important}
.bg_black_g3 {background-color:  #ebebeb !important}

.bg_black_theme01 {background-color:  #f3f3f3 !important}

.bg_bgrey {background-color: #e6e6e6 !important}.bg_g {background-color: #f5f5f5 !important}
.bg_g2 {background-color: #efefef !important}
.bg_dg {background-color: #3C4252 !important}
.bg_lg2 {background-color: #e8e8e8 !important}
.bg_lg3{background-color: #e0e0e0 !important}
.bg_run {background-color: #34c441  !important; opacity: 0.9}
.bg_idle {background-color: #f3822c !important; opacity: 0.9}
.bg_stop {background-color: #f44336 !important; opacity: 0.9}
.bg_off {background-color: #9a9a9a !important; opacity: 0.9}
.bg_blue {background-color: #0c91e5 !important; opacity: 0.9}
.bg_blue:hover {background-color: #2da4ef !important; opacity: 0.9}
.bg_blue2 {background-color: #0c91e5 !important; color: #fff !important}
.bg_th {background-color: #e0e7ec !important}
.bg_th_b {background-color: #464646 !important; color: #fff !important}
.bg_th_b_theme01 {background-color: #868686 !important; color: #fff !important}

.bg_td {background-color: #65798e !important; opacity: 0.9; color: #fff !important}
.bg_act{background-color: #f7f1f1 !important}

/*** font ***/
.ft12 {font-size: 12px !important}
.ft14 {font-size: 14px !important}
.ft16 {font-size: 16px !important}
.ft17 {font-size: 17px !important}
.ft18 {font-size: 18px !important}
.ft20 {font-size: 20px !important}
.ft22 {font-size: 22px !important}
.ft24 {font-size: 24px !important}
.ft26 {font-size: 26px !important}
.ft28 {font-size: 28px !important}
.ft30 {font-size: 30px !important}
.ft40 {font-size: 40px !important}
.ft45 {font-size: 45px !important}
.ft50 {font-size: 50px !important}
.ft80 {font-size: 80px !important}
.ftn {font-weight: normal !important}
.ft12th th{font-size: 12px !important}
.ft12td td{font-size: 12px !important}

/*** width ***/
.w50{width: 50px !important}
.w200{width: 200px !important}

/*** display ***/
.pdtd {display: block !important}

/*** margin ***/
.mg0{margin: 0 !important}
.mg10{margin: 10px !important}
.mgl3{margin-left:3px !important}
.mgl10{margin-left:10px !important}
.mgl20{margin-left:20px !important}
.mgl30{margin-left:30px !important}
.mgl50{margin-left:50px !important}
.mgr3{margin-right:3px !important}
.mgr10{margin-right:10px !important}
.m15_5{margin:15px 5px !important}
.m25_5{margin:25px 5px !important}
.mgt0{margin-top: 0 !important}
.mgt5{margin-top: 5px !important}
.mgt10{margin-top: 10px !important}
.mgt13{margin-top: 13px !important}
.mgt15{margin-top: 15px !important}
.mgt20{margin-top: 20px !important}
.mgt30{margin-top: 30px !important}
.mgt40{margin-top: 40px !important}
.mgt50{margin-top: 50px !important}
.mgt60{margin-top: 50px !important}
.mgt70{margin-top: 50px !important}
.mgb-5{margin-bottom: -5px !important}
.mgb0{margin-bottom: 0 !important}
.mg03{margin: 0 3px !important}
.mg_tb {margin : 7px -7px 7px 0 !important}
.mgb0 {margin-bottom: 0 !important}
.mgb05{margin-bottom:5px !important}
.mgb10{margin-bottom:10px !important}
.mgb20{margin-bottom:20px !important}
.mgb40{margin-bottom:40px !important}
.mgb50{margin-bottom:50px !important}
.mgb70{margin-bottom:70px !important}
.mgl010{margin: 0 0 0 10px !important}
.mgl030{margin: 0 0 0 30px !important}


/*** padding ***/
.pd0 {padding: 0 !important}
.pd3 {padding: 3px !important}
.pd5 {padding: 5px !important}
.pd7 {padding: 7px !important}
.pd8 {padding: 8px !important}
.pd10 {padding: 10px !important}
.pd20 {padding: 20px !important}
.pd25 {padding: 25px !important}
.pd30 {padding: 30px !important}
.pdt0 {padding-top:0 !important}
.pdt5 {padding-top:5px !important}
.pdt7 {padding-top: 7px !important}
.pdt10{padding-top:10px !important}
.pdt14{padding-top:14px !important}
.pdt15{padding-top:15px !important}
.pdl3{padding-left:3px !important}
.pdl10{padding-left:10px !important}
.pdl15{padding-left:15px !important}
.pdl30{padding-left:30px !important}
.pdl50{padding-left:50px !important}
.pdr3{padding-right:3px !important}
.pdr5{padding-right:5px !important}
.pdr10{padding-right:10px !important}
.pdr15{padding-right:15px !important}
.pd510 {padding: 5px 10px !important}
.pd05{padding: 0 5px !important}
.pd010{padding: 0 10px !important}
.pd020{padding: 0 20px !important}
.pd024{padding: 0 24px !important}
.pd1424{padding: 14px 24px !important}
.pd0l10{padding: 0 0 0 10px !important}
.pd0l15{padding: 0 0 0 15px !important}
.pd0202020{padding: 0 20px 20px 20px !important}
.pd0730{padding: 7px 30px !important}
.pd0740{padding: 7px 40px !important}
.pd1020{padding: 10px 20px !important}
.pd1510{padding: 15px 10px !important}
.pd0308{padding: 3px 8px !important}
.pd0212{padding: 2px 12px !important}
.pd0623{padding: 6px 23px !important}
.pd_work {padding: 50px 20px 20px 40px !important}

/*** position ***/
.p_abs{position: absolute !important; right: 0; padding: 20px; z-index: 9999}
.p_abs2{position: absolute !important; z-index: 9999; right: 0; margin: -3px 25px 0 0;}


.z-250 {
    z-index: 250;
}

.z-257 {
    z-index: 257;
}


/*** seclect ***/
.sc_seclect{
    border: 1px solid #c2cad8;
    padding: 7px 10px;
    border-radius: 3px;
    color: #3c4252;
    margin: 0 5px;
}
.sc_seclect:hover{
    border: 1px solid #0c91e5;
    padding: 7px 10px;
    border-radius: 3px;
    color: #3c4252;
    margin: 0 5px;
}

.sc_seclect_m{
    border: 1px solid #c2cad8;
    padding: 5px 8px;
    border-radius: 3px;
    color: #3c4252;
    margin: 5px 5px;
}

.sc_seclect_m:hover{
    border: 1px solid #0c91e5;
    padding: 5px 8px;
    border-radius: 3px;
    color: #3c4252;
    margin: 5px 5px;
}

.sc_seclect_m2{
    border: 1px solid #c2cad8;
    padding: 2px 8px 5px 8px;
    border-radius: 3px;
    color: #3c4252;
    margin: 0 5px 0 0;
    vertical-align: middle;
}

.sc_seclect_m2:hover{
    border: 1px solid #0c91e5;
    padding: 2px 8px 5px 8px;
    border-radius: 3px;
    color: #3c4252;
    margin: 0 5px 0 0;
    vertical-align: middle;
}

.seclect_c{
    background-color: #039be5;
    border: 1px solid #0081bf;
    padding: 7px 10px;
    border-radius: 3px;
    color: #fff;
    margin: 0 5px;
}
.seclect_c:hover{
    background-color: #039be5;
    border: 1px solid #efefef;
    padding: 7px 10px;
    border-radius: 3px;
    color: #fff;
    margin: 0 5px;
}

.seclect_c2{
    background-color: #039be5;
    border: 1px solid #0081bf;
    padding: 2px 8px 5px 8px;
    border-radius: 3px;
    color: #fff;
    margin: 0 5px;
}
.seclect_c2:hover{
    background-color: #039be5;
    border: 1px solid #efefef;
    padding: 2px 8px 5px 8px;
    border-radius: 3px;
    color: #fff;
    margin: 0 5px;
}

/*** font weight ***/
.b {font-weight: bold !important}
.bigt {font-size: 24px !important; padding: 30px 10px !important}
.bigt2 {font-size: 30px !important; padding: 30px 10px !important; color: red}
.p_clock {color: #c50000; font-size: 24px; font-weight: bold; padding-right: 10px}

/*** table weight ***/
.tlw th {font-weight: bold !important; background-color: #f1f4f7; padding: 5px 10px 5px 15px !important}
.tlw th,.tlw td {text-align: center; vertical-align: middle !important}
.tlw h2,.tlw h3 {margin: 0 !important}
.tlw2 th {background-color: #f7f1f1; font-weight: bold !important; padding: 5px 10px 5px 22px !important}
.tlw2 th,.tlw2 td {text-align: center; vertical-align: middle !important}
.tlw3 th {background-color: #f1f4f7; font-weight: bold !important; padding: 5px 25px !important}
.tlw3 th,.tlw3 td {text-align: center; vertical-align: middle !important}

.tlw4 thead .tlw4 th {background-color: #dee6ee !important; font-weight: bold !important; padding: 16px 30px}
.tlw4 th {background-color: #f1f4f7; font-weight: bold !important; padding: 5px 30px}
.tlw4 th,.tlw4 td {text-align: center; vertical-align: middle !important}

.tlw5 tbody .tlw5 th {background-color: #dee6ee !important; font-weight: bold !important; padding : 0px }
.tlw5 th {font-weight: bold !important; background-color: #f1f4f7; }
.tlw5 td {text-align: left; vertical-align: middle !important}
.tlw5 h2,.tlw5 h3 {margin: 0 !important}

.tlw_b thead th {font-size: 25px !important}
.tlw_b th {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #fefefe; font-weight: bold !important; background-color: #3e3e3e; padding: 8px 10px !important; font-size: 17px !important}
.tlw_b td {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #fefefe;}
.tlw_b.bc01 td {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #fefefe; font-size: 18px !important; padding: 10px !important}
.tlw_b.bc012 td {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #fefefe; font-size: 14px !important; padding: 10px !important}
.tlw_b.bc02 td {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #fefefe; font-size: 24px !important; padding: 10px !important}
.tlw_b.bc03 td {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #fefefe; font-size: 28px !important}
.tlw_b h2,.tlw_b h3 {margin: 0 !important}

.tlw_b2 thead th {font-size: 25px !important; background-color: #666 !important}
.tlw_b2 th {text-align: center; vertical-align: middle !important; border: 1px solid #bdbdbd !important; color: #333; font-weight: bold !important; background-color: #efefef; padding: 8px 10px !important; font-size: 17px !important; letter-spacing: -1px}
.tlw_b2 td {text-align: center; vertical-align: middle !important; border: 1px solid #636363 !important; color: #333;}
.tlw_b2.bc01 td {text-align: center; vertical-align: middle !important; border: 1px solid #bdbdbd !important; color: #333; font-size: 18px !important; padding: 13px 10px !important; background-color: #fff !important}
.tlw_b2.bc012 td {text-align: center; vertical-align: middle !important; border: 1px solid #bdbdbd !important; color: #333; font-size: 14px !important; padding: 13px 10px !important; background-color: #fff !important}
.tlw_b2.bc02 td {text-align: center; vertical-align: middle !important; border: 1px solid #bdbdbd !important; color: #333; font-size: 24px !important; padding: 10px !important; background-color: #fff !important}
.tlw_b2.bc03 td {text-align: center; vertical-align: middle !important; border: 1px solid #bdbdbd !important; color: #333; font-size: 28px; background-color: #fff !important}
.tlw_b2.bc04 td {text-align: center; vertical-align: middle !important; border: none !important; color: #333; font-size: 28px !important; background-color: #fff !important}
.tlw_b2 h2,.tlw_b h3 {margin: 0 !important}

.tlw_grid th {background-color: #f1f4f7; padding: 5px 10px 5px 15px !important; text-align: center; vertical-align: middle !important; white-space:nowrap;}
.tlw_grid td {text-align: center; vertical-align: middle !important; white-space:nowrap;}
.tlw_grid h2,.tlw_grid h3 {margin: 0 !important}


.tlw_pop th {font-weight: bold !important; background-color: #f1f4f7; padding: 5px 10px 5px 15px !important}
.tlw_pop th,.tlw_pop td {text-align: center; vertical-align: middle !important; font-size: 19px !important}
.tlw_pop h2,.tlw_pop h3 {margin: 0 !important}

.star {background-image: url(./img/star.png); background-position: 10px 49%; background-repeat: no-repeat;}


/*** icon ***/
.ico_m01 {margin: 0 5px -5px 0}

/*** color ***/
.c01 {color: #0c91e5 !important}
.c02 {color: #a367dc !important}
.c03 {color: #26C281 !important}
.c04 {color: #ea4141 !important}
.c05 {color: #555555 !important}
.c06 {color: #ffffff !important}
.c07 {color: #333333 !important}
.c08 {color: #e8e8e8 !important}
.c09 {color: #FF9F0D !important}
.c10 {color: #999999 !important}
.c11 {color: #00daff !important}
.c12 {color: #ff8484 !important}

/*** box ***/
.box_list { width: 100%; overflow-y:scroll}

.dsbbox{
    border: 1px solid #e7ecf1!important;
    padding: 12px 20px 15px;
    margin-top: 0;
    margin-bottom: 25px;
    background-color: #fff;
}

.imgtitle_b1{
    position: absolute;
    padding: 15px;
    color: #ffffff;
    background-color: #0c91e5;
}

.imgtitle_b2{
    position: absolute;
    padding: 15px;
    color: #ffffff;
    background-color: #a367dc;
}

.imgtitle_g{
    position: absolute;
    padding: 15px;
    color: #ffffff;
    background-color: #93ce1f;
}

.mark1{
    position: absolute;
    padding: 15px;
    color: #ffffff;
    background-color: #4d5058;
    min-width: 70px;
}
.dbox{
    border: 1px solid #efefef;
}
.dbox2{
    border: 1px solid #efefef; border-radius: 10px;
}

.sc {width: 300px !important; border: 3px solid #c2cad8 !important;}
.alarm_t {background: #e6e6e6;padding: 7px 15px; border: 1px solid #fff; border-radius: 20px; position: absolute; left: 300px; top: 20px; width: 80%; font-weight: bold}
.Lot_area {background: #e6e6e6;padding: 7px 15px; border: 1px solid #fff; border-radius: 20px; font-weight: bold}
.Lot_area_mo {background: #e6e6e6;padding: 7px 15px; border: 1px solid #fff; font-weight: bold}

.pie_title {position: absolute; font-weight: bold; font-size: 18px; top:38%; left:36%; z-index: 99; text-align: center}
.pie_title div{text-align: center; color: red; font-size: 30px; text-indent: 10px;}


.pd_li {/*padding: 20px 10px 20px 5px !important; */min-height: 100px; /*background-color: #d5d5d5 !important*/}
.pd_li_f {padding: 20px 10px 20px 20px !important; min-height: 238px; background-color: #d5d5d5 !important; border-radius: 20px 0 0 20px; color: #fff; font-weight: bold; font-size: 20px}
.pd_li2 {padding: 20px 10px 20px 5px !important; min-height: 100px; background-color: #d5d5d5 !important}
.pd_li2_f {padding: 20px 10px 20px 20px !important; min-height: 100px; background-color: #d5d5d5 !important}
.pd_li2_l {padding: 20px 20px 20px 10px !important; min-height: 238px; background-color: #d5d5d5 !important; border-radius:  0 20px 20px 0; color: #fff; font-weight: bold; font-size: 20px}

.pd_title {color: #fff; font-size: 18px; font-weight: bold; text-align: left; padding: 15px 15px 10px 15px; letter-spacing: -1px; /*border-radius: 10px 10px 0 0;*/}
.pd_title2 {color: #fff; font-size: 18px; font-weight: bold; text-align: center; padding: 15px 15px 10px 15px; letter-spacing: -1px;}
.pd_title2_sub {color: #fff; font-size: 14px; font-weight: bold; text-align: center; padding: 2px 15px; letter-spacing: -1px; border-top: 1px solid #fff}
.pd_box1 {background-color: #fafafa; padding: 10px 15px; border: 1px solid #eeeeee; min-height: 150px; border-radius: 0 0 10px 10px;}
.pd_box2 {background-color: #fafafa; padding: 10px; border: 1px solid #eeeeee; border-radius: 0 0 10px 10px;}
.pd_box3 {background-color: #fafafa; padding: 10px; border: 1px solid #eeeeee; min-height: 155px; min-width: 330px; border-radius: 0 0 10px 10px;}
.pd_box4 {background-color: #fafafa; padding: 10px 15px; border: 1px solid #eeeeee; min-height: 150px;}
.pd_box5 {background-color: #fafafa; padding: 10px 15px; border: 1px solid #eeeeee;}
.pd_imgbox {text-align: center; padding: 5px 10px}
.s_box {background-color: #ebebeb; border: 1px solid #ebebeb; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer}
.s_box:hover {background-color: #fff; border: 1px solid #ebebeb; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer}

.s_box_pdc01 {background-color: #34c441; min-width: 110px; box-shadow: 0 0 2px 2px #c5c5c5; border: 1px solid #34c441; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer; font-weight: bold; color: #fff}
.s_box_pdc02 {background-color: #ffb400; min-width: 110px; box-shadow: 0 0 2px 2px #c5c5c5; border: 1px solid #ffb400; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer; font-weight: bold; color: #fff}
.s_box_pdc03 {background-color: #f37564; min-width: 110px; box-shadow: 0 0 2px 2px #c5c5c5; border: 1px solid #f37564; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer; font-weight: bold; color: #fff}
.s_box_pdc04 {background-color: #a2a2a2; min-width: 110px; box-shadow: 0 0 2px 2px #c5c5c5; border: 1px solid #a2a2a2; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer; font-weight: bold; color: #fff}
.s_box_pdc05 {background-color: #00ccff; min-width: 110px; box-shadow: 0 0 2px 2px #c5c5c5; border: 1px solid #00ccff; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer; font-weight: bold; color: #fff}
.s_box_pdc06 {background-color: #e94cd8; min-width: 110px; box-shadow: 0 0 2px 2px #c5c5c5; border: 1px solid #e94cd8; border-radius: 10px; padding: 3px 10px; margin: 5px 0; text-align: center; cursor: pointer; font-weight: bold; color: #fff}

.pdc01 {color: #34c441; font-weight: bold;}
.pdc02 {color: #ffb400; font-weight: bold;}
.pdc03 {color: #f37564; font-weight: bold;}
.pdc04 {color: #3c4252; font-weight: bold;}
.pdc05 {color: #00ccff; font-weight: bold;}
.pdc06 {color: #e94cd8; font-weight: bold;}

.cl01 {color: #34c441; font-weight: bold; padding: 0 5px}
.cl02 {color: #f0ad4e; font-weight: bold; padding: 0 5px}
.cl03 {color: #f37564; font-weight: bold; padding: 0 5px}
.cl04 {color: #a2a2a2; font-weight: bold; padding: 0 5px}
.cl05 {color: #00ccff; font-weight: bold; padding: 0 5px}
.cl06 {color: #e94cd8; font-weight: bold; padding: 0 5px}

.cl_run {background-color: #93ce1f; color: #fff; border: solid 1px #fff; padding: 3px 10px; border-radius: 5px; font-size: 11px; font-weight: normal}
.cl_idle {background-color: #f0ad4e; color: #fff; border: solid 1px #fff; padding: 3px 10px; border-radius: 5px; font-size: 11px; font-weight: normal}
.cl_stop {background-color: #d83a0b; color: #fff; border: solid 1px #fff; padding: 3px 10px; border-radius: 5px; font-size: 11px; font-weight: normal}
.cl_off {background-color: #aaadb5; color: #fff; border: solid 1px #fff; padding: 3px 10px; border-radius: 5px; font-size: 11px; font-weight: normal}

.lot01 {padding: 5px; background-color: #67b7dc; border: 1px solid #67b7dc; border-radius: 5px}
.lot02 {padding: 5px; background-color: #6794dc; border: 1px solid #6794dc; border-radius: 5px}
.lot03 {padding: 5px; background-color: #6771dc; border: 1px solid #6771dc; border-radius: 5px}
.lot04 {padding: 5px; background-color: #8067dc; border: 1px solid #8067dc; border-radius: 5px}
.lot05 {padding: 5px; background-color: #a367dc; border: 1px solid #a367dc; border-radius: 5px}
.lot06 {padding: 5px; background-color: #c767dc; border: 1px solid #c767dc; border-radius: 5px}
.lot07 {padding: 5px; background-color: #dc67ce; border: 1px solid #dc67ce; border-radius: 5px}


/*** C104000 ***/
.flow_table {width: 100%; border-collapse: collapse; border: solid 3px #3C4252;}
.flow_table td {border: solid 10px #3C4252; vertical-align: top !important; background-color: #e9edef; letter-spacing: -1px;}
.pd_bg1 {background-color: #3C4252 !important;}
.pd_name {background-color: #efefef; padding: 10px; border-bottom: solid 1px #d2d2d2}
.pd_box ul {margin: 0 !important; padding: 0 !important; list-style: none}
.pd_box li {margin: 5px 0; padding: 0 !important; list-style: none}
.pd_box img {width: 70%; padding: 0}
.pd_box_run {background-color: #93ce1f; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_idle {background-color: #ff6f00; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_stop {background-color: #d83a0b; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_off {background-color: #aaadb5; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_alarm {background: -webkit-linear-gradient(top, #cb60b3 0%,#c146a1 50%,#a80077 51%,#db36a4 100%); color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_bms {background-color: #d879ff; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pie_title2 {position: absolute; font-weight: bold; font-size: 18px; top:38%; left:29%; z-index: 99; text-align: center}
.pie_title2 div{text-align: center; color: red; font-size: 30px; text-indent: 10px;}
/*.pd_box_run {background: -webkit-linear-gradient(top, #9dd53a 0%,#a1d54f 50%,#80c217 51%,#7cbc0a 100%); color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_idle {background: -webkit-linear-gradient(top, #ffb76b 0%,#ffa73d 50%,#ff7c00 51%,#ff7f04 100%); color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_stop {background: -webkit-linear-gradient(top, #f85032 0%,#f16f5c 50%,#f6290c 51%,#f02f17 71%,#e73827 100%); color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_off {background-color: #aaadb5; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_alarm {background: -webkit-linear-gradient(top, #cb60b3 0%,#c146a1 50%,#a80077 51%,#db36a4 100%); color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}
.pd_box_bms {background-color: #d879ff; color: #fff; border: solid 1px #fff; padding: 3px 20px; letter-spacing: -1px;}*/
.mt-element-ribbon {margin-bottom: 0 !important;}
.mt-element-ribbon .ribbon {margin: 0 !important; width: 100% !important}
.mt-element-ribbon .ribbon-content{padding: 5px 10px 10px 10px !important;}
.mt-element-ribbon .ribbon.ribbon-color-default {letter-spacing: -1px; color: #000 !important; font-weight: bold}
.shv {padding: 10px 20px !important; margin: 0 10px !important;}

/*** C104000_p01 ***/
.dview_area {text-align: center;padding: 10px; border: 1px solid #efefef;}
.dview_area img {height: 200px;}

/*** C203001 ***/
.chart_t {width: 200px; text-align: center; position: absolute; left: 50%; margin-left: -100px; bottom: 40px; border: 1px solid #efefef; border-radius: 10px; padding: 3px 20px; font-size: 36px; font-weight: bold;  color: #c50000; z-index: 999; background-color: #efefef; opacity: 1}
.chart_t2 {width: 240px; text-align: center; position: absolute; left: 50%; margin-left: -120px; bottom: 40px; border: 1px solid #efefef; border-radius: 10px; padding: 3px 20px; font-size: 36px; font-weight: bold;  color: #c50000; z-index: 999; background-color: #efefef; opacity: 1; letter-spacing: -1px;}
.chart_t3 {width: 240px; text-align: center; position: absolute; left: 50%; margin-left: -120px; bottom: 40px; border: 1px solid #efefef; border-radius: 10px; padding: 3px 20px; font-size: 30px; font-weight: bold;  color: #c50000; z-index: 999; background-color: #efefef; opacity: 1; letter-spacing: -1px;}
.chart_st {border: 1px solid #efefef; border-radius: 5px; padding: 3px 8px; font-size: 12px; color: #c50000; z-index: 999; background-color: #efefef; margin-bottom: -3px}
.ap_t {border: 1px solid #efefef; border-radius: 5px; padding: 5px 10px; font-size: 14px; color: #333; z-index: 999; background-color: #efefef; margin-bottom: -3px; font-weight: bold}

/*** C203002 ***/
.portlet.box>.portlet-title {border-bottom: 0 !important; padding: 0 10px !important; margin-bottom: 0 !important; color: #fff !important;}
.portlet.box>.portlet-title>.caption {padding: 11px 0 9px !important;}
.portlet>.portlet-title>.caption {float: left !important; display: inline-block !important; font-size: 18px !important; line-height: 18px !important;}
.portlet.box.green>.portlet-title>.caption, .portlet.box.green>.portlet-title>.caption>i {color: #FFF !important;}
.portlet>.portlet-title>.tools {float: right !important; display: inline-block !important; padding: 12px 0 8px !important;}
.add_t {text-align: center; color: #fff; font-size: 30px; width: 400px; margin: auto; padding: 40px 0 6px 0}
.add_st {text-align: right; color: #fff; font-size: 16px; width: 400px; margin: auto; border-top: 1px solid #fff; padding: 8px 0}

/*** C203004 ***/
.num_st {color: #efefef; text-align: center; font-size: 14px; margin-bottom: -35px}
.num_stt {color: #666; text-align: center; font-size: 14px; padding: 7px 0}
.num_count {color: #fff; text-align: center; font-size: 100px; font-weight: bold; background-color: #333; border: 3px solid #efefef; border-radius: 10px;}
.num_count2 {color: #fff; text-align: center; font-size: 50px; font-weight: bold; background-color: #333; border: 3px solid #efefef; border-radius: 10px; padding: 40px 10px 30px 10px}
.num_scount {background-color: #fff; border: 1px solid #999; text-align: center; border-radius: 10px; font-size: 16px;}
.snum_st {color: #333; text-align: center; font-size: 14px; padding-top: 80px}
.snum {color: #333; text-align: center; font-size: 40px;}
.num_st3 {width: 100%; font-size: 16px; padding: 5px 20px}
.num_st3 span {color: #ea4141;}

/*** C203005 ***/
.mo_tit1 {padding: 40px 20px 0 20px; font-size: 16px; text-align: center; width: 150px; margin: auto}
.mo_tit1 span {color: #ea4141; font-weight: bold}
.mo_tit2 {padding: 10px 30px 30px 30px; font-size: 16px; z-index: 999; margin-bottom: -3px; text-align: center;}
.mo_tit2 span {font-size: 56px; color: #ea4141; font-weight: bold}
.mo_tit3 {border: 1px solid #efefef; border-radius: 5px; padding: 10px 20px; font-size: 20px; background-color: #e4e4e4; text-align: center;}
.mo_tit3 span {font-size: 25px; color: #ea4141; font-weight: bold}

.num_t {width: 100%; text-align: center; font-size: 20px; margin: auto; border-bottom: 1px solid #999; padding: 8px 0}
.num_t span {color: #ea4141; font-size: 60px;}
.num_st {width: 100%; font-size: 16px; padding: 5px 20px}
.num_st span {font-size: 16px;}
.num_sta {border: 1px solid #efefef; border-radius: 5px; padding: 10px 20px; font-size: 26px; background-color: #e4e4e4; text-align: center;}
.num_sta span {font-size: 26px; color: #ea4141;}

/*** C204005 ***/
.num_t2 {padding: 10px 20px 0 20px; font-size: 16px; text-align: center; width: 150px; margin: auto; color: #ea4141; font-weight: bold}
.num_st2 {padding: 10px 30px 5px 30px; font-size: 16px; text-align: center; border-bottom: 1px solid #999;}
.num_st2 span {font-size: 40px; color: #ea4141; font-weight: bold}
.num_sta2 {padding: 10px 20px; font-size: 20px; text-align: right;}
.num_sta2 span {font-size: 25px; color: #ea4141; font-weight: bold}
.num_sts2 {border: 1px solid #efefef; border-radius: 5px; padding: 10px 20px; font-size: 20px; background-color: #e4e4e4; text-align: center;}
.num_sts2 span {font-size: 25px; color: #ea4141; font-weight: bold}
.ico_box {width: 100% !important; height: 170px; text-align: center; font-size: 70px; color: #dc6788 !important}
.ico_box2 {width: 100% !important; height: 230px; text-align: center; font-size: 120px; color: #dc6788 !important}

/*** C204011 ***/
.line_mo {background-color: #364150; text-align: center; padding: 70px 50px}
.line_mot1 {font-size: 30px; color: #fff}
.line_mot2 {font-size: 100px; color: #66c7f5}
.line_mot2 span {color: #fff}

.line_mo2 {background-color: #0c91e5; text-align: center; padding: 50px 50px}
.line_mo2t1 {font-size: 30px; color: #fff}
.line_mo2t2 {font-size: 70px; color: #fff}
.line_mo2t2 span {font-size: 30px; color: #fff}



.line_mtitle {color: #666; text-align: center; font-size: 40px; padding: 30px 10px}
.line_mtitle2 {color: #666; text-align: center; font-size: 30px; padding: 30px 10px}

/*** C501000 ***/
.mt-timeline-2 > .mt-container > .mt-item {margin-bottom: -170px !important;}
.time_ct {font-size: 14px; font-weight: bold; color: #0d1318; padding: 3px 0; color: #666}
.time_ct span{font-size: 14px; font-weight: normal !important;}
.time_ctarea {position: absolute; padding: 10px 0 0 300px;}
.time_num {font-weight: bold; font-size: 24px; text-align: right}
.time_scl {overflow-y: scroll; height: 600px;}

/*** C606000 ***/
.tbbox {padding: 60px 0 30px 20px; color: #fff; font-size: 14px; margin-bottom: 20px;}
.gbbox {padding: 40px 0 10px 20px; color: #fff; font-size: 14px; margin-bottom: 20px;}

/*** progress ***/
.progress {margin: 0 !important}

/*** pop ***/
.pop_btn {right: 30px !important; position: absolute; margin: 0 !important;}

/*** blink ***/
/* for Chrome, Safari */
@-webkit-keyframes blink {
    0% {background-color: red;}
    50% {background-color: yellow;}
}

.blinkcss {
    animation-name: blink;
    animation-duration: 1s;
    animation-timing-function: step-end;
    animation-iteration-count: infinite;
}

/*** mt-radio ***/
.mt-radio.mt-radio-outline:hover > input:not([disabled]):checked ~ span, .mt-radio.mt-radio-outline > input:checked ~ span, .mt-radio.mt-checkbox-outline:hover > input:not([disabled]):checked ~ span, .mt-radio.mt-checkbox-outline > input:checked ~ span, .mt-checkbox.mt-radio-outline:hover > input:not([disabled]):checked ~ span, .mt-checkbox.mt-radio-outline > input:checked ~ span, .mt-checkbox.mt-checkbox-outline:hover > input:not([disabled]):checked ~ span, .mt-checkbox.mt-checkbox-outline > input:checked ~ span{color: #fff !important; background-color: #fff !important}

/*** z-index ***/
.zi{z-index: 999999999 !important}

/*** a ***/
.an{text-underline: none !important; text-decoration: none !important}

/*** border ***/
.bdds { border: 1px solid #999; border-style: dashed}
.bd_none {border:none !important}
.bd_red { border: 1px solid #e3342f;}
.bd_card {border: 5px solid #4a4a4a; background-color: #333;}
.bd_card_run {border: 5px solid #1e6311; background-color: #34c441;}
.bd_card_idle {border: 5px solid #823d0c; background-color: #f0ad4e;}
.bd_card_off {border: 5px solid #823d0c; background-color: #a2a2a2;}
.bd_card_stop {border: 5px solid #961e1e; background-color: #e7505a;}
.card_act {opacity: 0.5;}

.bdb {border: 1px solid #111 !important;}
.bdb_theme01 {border: 1px solid #c7c7c7 !important;}
.bd_pt {border-bottom: 1px solid #636363 !important;}

/*** input ***/
.t_input {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 100%}
.t_input:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 100%}
.t_input_n {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px;}
.t_input_n:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px;}
.t_input_s {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 80px; margin-right: 3px;}
.t_input_s:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 80px; margin-right: 3px;}
.t_input_xs {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px; margin-right: 3px; max-width: 30px !important}
.t_input_xs:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px; margin-right: 3px; max-width: 30px !important}
.t_input_xs2 {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px; margin-right: 3px; max-width: 40px !important}
.t_input_xs2:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px; margin-right: 3px; max-width: 40px !important}
.t_input_cal {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 160px; margin-right: 3px;}
.t_input_cal:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 160px; margin-right: 3px;}
.t_input_big {border: 3px solid #0c91e5; font-size: 20px; padding: 3px 10px; border-radius: 3px; width: 100%}
.t_input_big2 {border: 3px solid #0c91e5; font-size: 30px; padding: 5px 10px; border-radius: 3px; width: 100%}
.t_input_work {border: 3px solid #0c91e5; font-size: 20px; padding: 3px 10px; border-radius: 3px; width: 90%; background-color: #4a4a4a !important; color: #fff}
.t_input_er {border: 3px solid #adadad; font-size: 40px; padding: 10px 10px; border-radius: 3px; width: 100%; background-color: #4a4a4a !important; color: #fff; text-align: right}

/*** textarea ***/
.t_textarea {border: 1px solid #c2cad8; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 100%; height: 80px}
.t_textarea:hover {border: 1px solid #0c91e5; font-size: 14px; padding: 3px 10px; border-radius: 3px; width: 100%; height: 80px}

/*** select ***/
.t_seclect{
    border: 1px solid #c2cad8;
    padding: 5px 8px;
    border-radius: 3px;
    color: #3c4252;
    width: 100%;
}

.t_seclect:hover{
    border: 1px solid #0c91e5;
    padding: 5px 8px;
    border-radius: 3px;
    color: #3c4252;
    width: 100%;
}

.t_seclect_s{
    border: 1px solid #c2cad8;
    padding: 2px 5px 6px 5px;
    border-radius: 3px;
    color: #3c4252;
    margin-right: 3px;
}

.t_seclect_s:hover{
    border: 1px solid #0c91e5;
    padding: 2px 5px 6px 5px;
    border-radius: 3px;
    color: #3c4252;
    margin-right: 3px;
}

.t_seclect_st{
    border: 1px solid #c2cad8;
    padding: 4px 5px 4px 5px;
    border-radius: 3px;
    color: #3c4252;
    margin-right: 3px;
}

.t_seclect_st:hover{
    border: 1px solid #0c91e5;
    padding: 4px 5px 4px 5px;
    border-radius: 3px;
    color: #3c4252;
    margin-right: 3px;
}

.t_seclect_lng{
    border: 1px solid #0c91e5;
    background-color: #0c91e5;
    padding: 4px 5px 5px 5px;
    border-radius: 3px;
    color: #fff;
    font-size: 17px;
    margin: 0 0 0 0;
    width: 100px;
}

.t_seclect_lng:hover{
    border: 1px solid #0b82ce;
    background-color: #0b82ce;
    padding: 4px 5px 5px 5px;
    border-radius: 3px;
    color: #fff;
    font-size: 17px;
    margin: 0 0 0 0;
    width: 100px;
}

/*** 좌측 로고 ***/
.left_logo {border-radius: 0 !important; width: 90% !important; height: 25% !important}


/*** title box ***/
.title_box {background-color: #efefef !important; border: 1px solid #efefef; border-radius: 3px; padding: 3px 10px; margin-bottom: 10px; font-weight: bold}
.title_box_b {background-color: #fff !important; border: 1px solid #efefef; border-radius: 3px; padding: 10px 15px; margin-bottom: 10px; font-weight: bold; text-align: center; font-size: 18px}

/*** min-height ***/
.mh0{min-height: 0 !important}

/*** line-height ***/
.line_h30{line-height: 30px !important}
.line_h20{line-height: 20px !important}

/*** height ***/
.height0 {height: 0 !important}

/*** clock ***/
.m_clock {width: 100%; padding: 30px; background-color: #333; color: #fff; font-size: 50px;}

/*** flow ***/
.pg_line_bg {border-right: 1px solid #fff !important; height: 20px !important}

/*** time line ***/
.mt-timeline-line {left: 49.5% !important}

/*** 진행률 ***/
.ing_box{background-color: #a2a2a2; border: 1px solid #a2a2a2; border-radius: 12px; padding: 1px 10px; margin: 5px 0; text-align: center; font-weight: bold; color: #fff; font-size: 14px !important}

/*** Button Size ***/
.size_max {width: 100% !important; height: 83px; display: block}

.size_max2 {width: 220px !important; height: 145px; font-size: 30px; display: block}

.size_max3 {width: 220px !important; height: 145px; font-size: 80px; display: block}

/*** 작업리스트 CARD ***/
.card_box_run {background-color: #26C281; width: 100%; border: 1px solid #26C281 ; border-radius: 12px; padding: 3px 10px; text-align: center; font-weight: bold; color: #fff; font-size: 14px !important}

/*** SOP ***/
.mt-action-details {vertical-align: middle !important; font-size: 20px !important}
.mt-actions .mt-action .mt-action-body .mt-action-row .mt-action-info {vertical-align: middle !important}
.mt-action-info {vertical-align: middle !important}
.mt-actions .mt-action .mt-action-body .mt-action-row .mt-action-buttons {padding-top: 0 !important; width: 200px !important}
.mt-actions .mt-action {border-bottom: 1px solid #bdbdbd !important; padding: 7px 0 4px 0 !important;}
.mt-actions .mt-action:last-child {border-bottom: none !important; padding: 7px 0 2px 0 !important;}


/*.mt-action-details span {font-size: 24px !important}*/
.mt-action-icon i {font-size: 30px !important}

/*** progress ***/
.progress {background-color: #e2e2e2 !important; height: 30px !important}
.progress-bar {font-size: 18px !important; line-height: 30px !important}
.progress-bar-danger {background-color: #908c8c !important}

/*** avatar ***/
#avatar_sb img {border-radius: 0 !important}

/*** scrollbar ***/
/*::-webkit-scrollbar{width: 16px;}
::-webkit-scrollbar-track {background-color:#000;}
::-webkit-scrollbar-thumb {background-color:#f1ef79;border-radius: 10px;}
::-webkit-scrollbar-thumb:hover {background: #555;}*/

.TSCellHeader, .GridMain1.GridMain2 .TSCellHeader {background-color: #666}
.GridMain1.GridMain2 .TSClassReadOnly {color: #000} 

/* 인보이스관련 css */ 
.invoiceDiv {font-family: 'Exo', Muli, Roboto, Helvetica Neue, Arial, sans-serif;} 
.invoiceTitle { font-family: 'Exo', Muli, Roboto, Helvetica Neue, Arial, sans-serif; font-size: 18pt; font-weight: bold; text-align: center; color: blue; }
.invoiceTable { width: 100%; background-color: #fff; }
.invoiceTable tr td { font-size: 9pt; width: 50%; padding-top: 10px; padding-bottom: 10px; padding-left: 0px; padding-right: 0px; vertical-align : top;} 
.invoiceTable tr .tdl { padding-left: 3px; border-bottom: 1px solid gray; border-top: solid 1px gray; border-right: solid 1px gray;}
.invoiceTable tr .tdr { padding-left: 3px; border-bottom: 1px solid gray; border-top: solid 1px gray; border-left: solid 1px gray; } 
.invoiceTable .ialignRight {text-align: right; padding-right: 15px;} 
.invoiceTable tr td table { border-collapse: collapse; width: 100%; } 
.invoiceTable tr td table tr td {padding: 0px; margin: 0px;}
.invoiceTable tr td table tr {padding: 0px; margin: 0px;}
.invoiceTable .iw100 {width: 99%; padding-right: 1%;}
.invoiceTable .iw50 {width: 50%;}
.invoiceTable .iw36 {width: 36%;}
.invoiceTable .iw64 {width: 64%;}
.iwp {width: 400px;}
.invoiceTextAreaw_100 {width: 100%; height: 99%; padding-bottom: 1%;}
.invoiceSignHeight { height: 60px;}
.invoiceTable .pointFont {color: blue;}
.invoiceTable .iBold { font-weight: bold;}
.invoiceTable tr td div ul{ margin: 0px; padding: 0px; }
.invoiceTable tr td div ul li{ list-style: none; } 
.invoiceList { width: 100%;}
.invoiceList tr td { border-bottom: 1px solid #dadadaf1; height: 17px; border-top: solid 1px #dadadaf1; }
.invoiceList tr th { text-align: center; }
.icw1 { width: 10px; text-align: right;}
.icw2 { width: 250px; text-align: center;}
.icw3 { width: 150px; text-align: center;}
.icw4 { width: 200px; text-align: right;}
.icw5 { width: 250px; text-align: right;}
.icw6 { width: 250px; text-align: right;}
.i_titleDiv {width: 100%;}
.i_titleDiv ul li{list-style: none; float: left;}
.i_titleDiv ul {margin: 0; padding: 0;}
.iPLeft {padding-left: 15px;} 
.invoiceTable2 { border-top: 3px double red; width: 100%;  font-size: 9pt;}
.invoiceTable2 table {width: 100%;}
.invoiceTable2 table tr td { font-size: 9pt; }
.invoiceTable2 .iw50{ width: 50%;}
.invoiceTable2 .iLeftLine{ border-left: solid 1px black;}
.invoiceTable2 .iRightLine{ border-right: solid 1px black;}
.invoiceTable2 .iBottomLine{ border-bottom: solid 1px black;}
.invoiceTable2 .ialignRight { text-align: right; padding-right: 10px;} 
.invoiceTable2 .ivalignTop { vertical-align : top;} 
.invoiceTable2 .iPaddingTop { padding-top: 30px;} 
.invoiceTable2 .iTextAlign { text-align: center;} 
.invoiceTable2 .col1 {width: 155px;}
.invoiceTable2 .col2 {width: 4px;}
.invoiceTable2 .col4 {width: 100px;} 
.backGray { background-color:  #e6e6e6f1;}
.invoiceTable2 .ilineGray { border-bottom: 1px solid #dadadaf1;} 
.invoiceTable3 { width: 100%; font-size: 9pt; }
.invoiceTable3 .iAlingLeft { text-align: left;} 
.invoiceTable3 .iAlingCenter{ text-align: center;} 
.invoiceTable3 .iAlingRight { text-align: right;} 
.invoiceTable3 .iWidth30 { width: 10%;} 
.invoiceTable3 tr td { width: 33.3%; color: #bdbdbdf1;}
.invoiceDivAbs { position: absolute; z-index: 100000; padding-top: 1200; left: 200; border: solid 1px red;}

/* 기준정보 > 공정관리 */
.imgDiv { float: left; }
.imgDiv2 {float: left; width: 100%; height: 165px; overflow: scroll;}
.noIMG2 { margin-top: 10px; margin-left: 10px;width: 100px; height: 100px; background-color: rgb(204, 204, 204); color: white; text-align: center; } 
.noIMG { margin-top: 10px; margin-left: 10px;width: 100px; height: 100px; background-color: rgb(204, 204, 204); color: white; text-align: center; padding-top: 35%;} 
.yesIMG {  margin-top: 10px; margin-left: 10px;width: 100px; height: 100px; padding-top: 35%; text-align: center; display: block; } 
.info_textArea_w_100 {width: 99%; height: 142px; padding-top: 1%; padding-bottom: 1%;}
.imgStyle { top:0; padding: 0; margin-top: -40px; }

/** summary  C101010 */
.summary_box1 {
    float: left;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 1px 9px;
    margin-left: 4px;
    margin-right: 1px;
    color: #ffffff;   
}
.summary_box2 {
    float: left;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 1px 9px;
    margin-left: 4px;
    margin-right: 1px;
    color: #000000;   
}
.summary_box3 {
    float: left;
    border: 1px solid #efefef;
    border-radius: 4px;
    padding: 1px 9px;
    margin-right: 1px;
    color: #000000;   
}
.summary_100_p { width: 100%;}
.summary_m_b_10 { margin-bottom: 10px;}
.summary_box2 > ul {margin: 0; padding: 0;}
.summary_box2 > ul > li {margin: 0; padding: 0px 12px; list-style: none;}
.summary_box1 > ul {margin: 0; padding: 0;}
.summary_box1 > ul > li {margin: 0; padding: 0px 12px; list-style: none;}

.summary_bg1{
    background-color: #2ab441;
}
.summary_bg2{
    background-color: #8f8f8f;
}
.summary_bg3{
    background-color: #9df4ff;
}
.summary_bg4{
    background-color: #ebebeb;
}
.summary_bg5{
    background-color: #c45a5a;
}
.summary_bg6{
    background-color: #ffd2d2;
}

.s_tb_box { width: 100%;}
.s_tb_box tr td{ padding: 0px; margin: 0px;}
.s_red_cir{
    position: relative;
    float: left;
    width: 26px;
    height: 26px;
    border-radius: 13px; 
    background-color: red;
    font-weight: bold; 
    vertical-align: middle;
    margin-top: 25px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    color: white;
    font-size: 1.3em;
}
.s_scroll_y{overflow-y: auto;}
.s_box_height { height: 375px;}



/* 100020 대시보드 */
/* Dashboard */

.f-clear:after {content: " "; height: 0; display: block; visibility: hidden; clear: both;}
* html .f-clear {height: 1%; }
.f-clear {display: block; min-height: 1%;}
.clear {clear: both;}
.f-left {float: left;}
.f-right {float: right;}

.t-left {text-align: left !important;}
.t-right {text-align: right !important;}
.t-center {text-align: center !important;}
.t-indent {text-indent: -.55em; padding-left: .55em;}

.c-red {color: #f52020 !important;}
.c-blue {color: #0793e9 !important;}
.c-green {color: #00a651 !important;}
.c-orange {color: #f26c4f !important;}
.c-pupple {color: #9028b6 !important;}

.dashboard-wrap {height: 100%; background: #f3f3f3; line-height: 1;} 
.dashboard-wrap p, .dashboard-wrap h1, .dashboard-wrap h2, .dashboard-wrap h3, .dashboard-wrap h4, .dashboard-wrap h5 {margin: 0;}
.dashboard-wrap h1, .dashboard-wrap h2, .dashboard-wrap h3, .dashboard-wrap h4, .dashboard-wrap h4, .dashboard-wrap h5 {font-weight: 600;}
.dashboard-wrap .header {height: 70px; background: #c12b0c; line-height: 70px; padding:0 35px;}
.dashboard-wrap .header h1 {float: left; line-height: 70px;}
.dashboard-wrap .header .loginfo {float: right; display: inline-block;}
.dashboard-wrap .header .profile {display: inline-block; font-size: 17px; color: #fff; margin-right: 20px; vertical-align: middle; line-height: 40px;}
.dashboard-wrap .header .profile img {width: 30px; margin-right: 10px; vertical-align: middle; margin-top: -5px;}
.dashboard-wrap .header .logintime {display: inline-block; color: #fff; font-size: 12px; border: 1px solid rgba(255,255,255,.7); height: 30px; line-height: 28px; padding: 0 15px; border-radius: 30px; vertical-align: middle;}
.dashboard-wrap .header .logintime h3 {display: inline-block; font-weight: 300; margin-top: -20px; font-size: inherit;}
.dashboard-wrap .header .logintime p {display: inline-block; font-weight: 600; margin: 0;}
.dashboard-wrap .header .logintime p::before {display: inline-block; content: '|'; color: rgba(255,255,255,.5); vertical-align: top; font-size: 10px; margin: 0 5px;}
.dashboard-wrap .summary-wrap {background: #333; text-align: center; padding: 0 20px;}
.dashboard-wrap .summary-wrap .summary-container {max-width: 1920px; margin: 0 auto;}
.dashboard-wrap .summary-wrap .guage-wrap {width: 19%; margin: 0; display: inline-block; text-align: center; background: url(./img/bg_guage.png) no-repeat 50% 40%; height: 0; padding-top: 13%; vertical-align: bottom; background-size: 100% auto; font-size: 1.2em; position: relative;}
.dashboard-wrap .summary-wrap .guage-wrap .guage-container {position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); width: 80%; height: 80%;}
.dashboard-wrap .summary-wrap .guage-wrap .guage-container .title {position: absolute; bottom: 18%; left: 50%; transform: translateX(-50%); width: 52%; height: 52%; background: url(./img/bg_guagetit.png) no-repeat; background-size: 100% 100%; z-index: 2;}
.dashboard-wrap .summary-wrap .guage-wrap .guage-container .title h2 {color: #fff; position: absolute; bottom: 6%; width: 100%; text-align: center; font-weight: 600; font-size: 1em; margin: 0; letter-spacing: -.06em;}
.dashboard-wrap .summary-wrap .guage-wrap .guage-container .title p {position: absolute; top: 46%; left: 50%; transform: translateX(-50%) translateY(-50%); font-size: 3em; font-weight: 600; margin: 0;}
.dashboard-wrap .summary-wrap .guage-wrap .guage-container .needle {width: 22%; position: absolute; bottom: 32%; left: 39%; transform: rotate(-90deg); z-index: 1; transform-origin: 50% 103%; transition: all ease 1s;}
.dashboard-wrap .summary-wrap .guage-wrap .guage-container .needle img {width: 100%;}
.dashboard-wrap .summary-wrap .guage-wrap ul {margin: -1.3em 0 0; padding: 0;}
.dashboard-wrap .summary-wrap .guage-wrap ul li {color: #fff; font-size: .8em; font-weight: 300; display: inline-block; padding: 0 10px;}
.dashboard-wrap .summary-wrap .guage-wrap ul strong {}
.dashboard-wrap .summary-wrap .summary-box {width: 18%; display: inline-block; text-align: center; background: url(./img/bg_summary.png) no-repeat 50% 56%; background-size: 100% auto; height: 0; padding-top: 10%;vertical-align: top; font-size: 1.22em; margin: 2% .3% 1.6%; position: relative;}
.dashboard-wrap .summary-wrap .summary-box .summary-box-con {position: absolute; top: 47%; left: 50%; transform: translateX(-50%) translateY(-50%); width: 100%; display: inline-block;}
.dashboard-wrap .summary-wrap .summary-box.summary1,
.dashboard-wrap .summary-wrap .summary-box.summary2 {float: left;}
.dashboard-wrap .summary-wrap .summary-box.summary3,
.dashboard-wrap .summary-wrap .summary-box.summary4 {float: right;}
.dashboard-wrap .summary-wrap .summary-box h2 {font-size: 1em; font-weight: 600; margin: 0 0 3%; letter-spacing: -.05em;}
.dashboard-wrap .summary-wrap .summary-box p {margin: 0;}
.dashboard-wrap .summary-wrap .summary-box .fs1 {font-size: 2.8em; font-weight: 600; margin-bottom: 3%;}
.dashboard-wrap .summary-wrap .summary-box .fs2 {font-size: 1em; font-weight: 300;}
.dashboard-wrap .summary-wrap .summary-box p i {font-style: normal;}

.ds-content-wrap {background: #f3f3f3; padding: 35px 26px 0; font-size: 0;}
.ds-content-wrap .ds-wrap {display: inline-block; width: 50%; vertical-align: top; font-size: 1.6rem; padding: 0 9px; box-sizing: border-box;}
.ds-content-wrap .ds-wrap .title:after {content: " "; height: 0; display: block; visibility: hidden; clear: both;}
* html .ds-content-wrap .ds-wrap .title {height: 1%; }
.ds-content-wrap .ds-wrap .title {display: block; min-height: 1%;}
.ds-content-wrap .ds-wrap h3 {float: left; font-size: 1.3em; padding-bottom: .6em; font-weight: 600; letter-spacing: -.05em;}
.ds-content-wrap .ds-wrap .btn-more {float: right; margin: 5px 15px 5px 0;}
.ds-content-wrap .ds-wrap .ds-box {border: 1px solid #d1d1d1; border-top: 5px solid #666; border-radius: 3px; box-shadow: 3px 3px 3px rgba(0,0,0,.05); background: #fff; padding: 0; height: 280px; margin-bottom: 35px; overflow-y: auto;}
.ds-content-wrap .ds-wrap .ds-box.pd {padding: 35px 40px; }
.ds-content-wrap .ds-wrap .ds-box .item1 {text-align: center; border-radius: 3px; overflow: hidden; margin-bottom: 1.2em;}
.ds-content-wrap .ds-wrap .ds-box .item1 h4 {background: #57b92c; color: #fff; padding: 10px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item1 h4 span {font-size: .75em; font-weight: 300;}
.ds-content-wrap .ds-wrap .ds-box .item1 p {background: #e3f5d0; color: #000; padding: 15px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item2 {text-align: center; border-radius: 3px; overflow: hidden; margin-bottom: 20px;}
.ds-content-wrap .ds-wrap .ds-box .item2 h4 {background: #9e9d9d; color: #fff; padding: 10px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item2 h4 span {font-size: .75em; font-weight: 300;}
.ds-content-wrap .ds-wrap .ds-box .item2 p {background: #eff0f1; color: #000; padding: 15px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item3 {text-align: center; border-radius: 3px; overflow: hidden; margin-bottom: 1.2em;}
.ds-content-wrap .ds-wrap .ds-box .item3 h4 {background: #d93f1f; color: #fff; padding: 10px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item3 h4 span {font-size: .75em; font-weight: 300;}
.ds-content-wrap .ds-wrap .ds-box .item3 p {background: #f8ebe9; color: #000; padding: 15px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item4 {text-align: center; border-radius: 3px; overflow: hidden; margin-bottom: 20px;}
.ds-content-wrap .ds-wrap .ds-box .item4 h4 {background: #ff8a00; color: #fff; padding: 10px 10px; font-size: 1.15em;}
.ds-content-wrap .ds-wrap .ds-box .item4 h4 span {font-size: .75em; font-weight: 300;}
.ds-content-wrap .ds-wrap .ds-box .item4 p {background: #ffeed9; color: #000; padding: 15px 10px; font-size: 1.15em;}

.ds-content-wrap .ds-wrap .ds-box > div:last-of-type {margin-bottom: 0;}

.tbl1 {border-collapse: collapse; border: 0; padding: 0; margin: 0 auto; border-bottom: 1px solid #bbb; width: 100%; background:#fff; font-size: 1em;}
.tbl1 th {background: #f6f7f8; color: #000; font-weight: 600; vertical-align: middle; padding: .85em 1.5em; border-bottom: 1px solid #d1d1d1; text-align: center;}
.tbl1 td {background: #fff; color: #333; font-weight: 400; border-bottom: 1px solid #d1d1d1; vertical-align: middle; text-align: center; padding: .85em 1.5em;}
.tbl1 .s2 {font-size: 1.4em;}
.tbl1 td a {color: #000 !important;}

.tbl2 {border-collapse: collapse; border: 0; padding: 0; margin: 0 auto 1.15em; border-bottom: 1px solid #fff; width: 100%; background:#f8ebe9; font-size: 1em;}
.tbl2 th {background: #e67f72; color: #fff; font-weight: 400; vertical-align: middle; padding: .85em 1.5em; border-bottom: 1px solid #fff; border-right: 1px solid #fff;}
.tbl2 td {background: #f8ebe9; color: #000; font-weight: 400; border-bottom: 1px solid #fff; border-right: 1px solid #fff; vertical-align: middle; text-align: center; padding: .85em 1.5em;}
.tbl2 .s2 {color: #f52020; font-size: 1.8em;}

.tbl3 {border-collapse: collapse; border: 0; padding: 0; margin: 0 auto 1.15em; border-bottom: 1px solid #fff; width: 100%; background:#e3f5d0; font-size: 1em;}
.tbl3 th {background: #57b92c; color: #fff; font-weight: 400; vertical-align: middle; padding: .85em 1.5em; border-bottom: 1px solid #fff; border-right: 1px solid #fff;}
.tbl3 td {background: #e3f5d0; color: #000; font-weight: 400; border-bottom: 1px solid #fff; border-right: 1px solid #fff; vertical-align: middle; text-align: center; padding: .85em 1.5em;}
.tbl3 .s2 {color: #57b92c; font-size: 1.8em;}

.num {background: #0c91e5; color: #fff; width: 20px; height: 20px; border-radius: 3px; display: inline-block; text-align: center; line-height: 20px; font-weight: 300; margin: 0 12px 0 0; font-size: 13px; vertical-align: top;}
.num-red {background: #f52020; color: #fff; width: 20px; height: 20px; border-radius: 3px; display: inline-block; text-align: center; line-height: 20px; font-weight: 300; margin: 0 12px 0 0; font-size: 13px; vertical-align: top;}

@media screen and (max-width: 1920px) {
	.dashboard-wrap .summary-wrap {font-size: .64vw;}
}

@media screen and (max-width: 1400px) {
	.ds-content-wrap .ds-wrap {width: 50%;}
	.tbl1 th, .tbl1 td {padding: .85em .5em;}
	.ds-content-wrap .ds-wrap .ds-box.pd {padding: 20px 20px;}
}

@media screen and (max-width: 1100px) {
    .dashboard-wrap .summary-wrap .guage-wrap {width: 30%; margin: 0 0 0 35%; display: block; padding-top: 20%; font-size: 1.9em;}
    .dashboard-wrap .summary-wrap .summary-box {font-size: 1.6em;}
	.dashboard-wrap .summary-wrap .summary-box.summary1, .dashboard-wrap .summary-wrap .summary-box.summary2, 
	.dashboard-wrap .summary-wrap .summary-box.summary3, .dashboard-wrap .summary-wrap .summary-box.summary4 {float: none;}
	.dashboard-wrap .summary-wrap .summary-box {width: 23.5%; padding-top: 14%; font-size: 1.8em;}
	.tbl2 th, .tbl3 th, .tbl2 td, .tbl3 td {padding: .85em .6em;}
}
	
@media screen and (max-width: 800px) {
	.ds-content-wrap {padding: 20px 15px 0;}
	.ds-content-wrap .ds-wrap {font-size: 1.28rem;}
	.dashboard-wrap .summary-wrap .guage-wrap {width: 60%; margin: 0 0 0 20%; padding-top: 40%; font-size: 3.5em;}
	.dashboard-wrap .summary-wrap .summary-box {width: 40%; padding-top: 23%; font-size: 2.8em; margin-bottom: -3%;}
	.dashboard-wrap .summary-wrap .summary-container {padding-bottom: 5%;}
	.num, .num-red {vertical-align: middle; margin-top: -2px;}
}

@media screen and (max-width: 600px) {
    .ds-content-wrap .ds-wrap {width: 100%;}
    .dashboard-wrap .header h1 {line-height: 40px;}
	.dashboard-wrap .header h1 img {width: 70px;}
	.dashboard-wrap .header {height: 40px; line-height: 40px; padding: 0 20px;}
	.dashboard-wrap .header .profile {font-size: 12px; margin-right: 10px;}
	.dashboard-wrap .header .logintime {font-size: 11px; height: 20px; line-height: 18px; padding: 0 8px;}
	.dashboard-wrap .header .profile img {width: 20px; margin-right: 3px; margin-top: -4px;}
}

@media screen and (max-width: 400px) {
	.dashboard-wrap .summary-wrap .guage-wrap {width: 90%; margin: 0 0 0 5%; padding-top: 61.9%; font-size: 5.5em;}
	.dashboard-wrap .summary-wrap .summary-box {width: 48%; padding-top: 28%; font-size: 3.8em;}
	.dashboard-wrap .summary-wrap {padding: 10% 20px 2%;}
	.dashboard-wrap .header .loginfo {float: none; clear: both; display: block; text-align: center;}
}


/*경영정보*/
.manageinfo-wrap {height: 100%;}
.manageinfo-wrap .left-wrap {width: 250px;}
.manageinfo-wrap .client-wrap {background: #424242; padding: 15px 20px; text-align: center; width: calc( 100% - 2px );}
.manageinfo-wrap .client-wrap .ico-client {margin: 0 8px 0 0; vertical-align: middle; width: 12px;}
.manageinfo-wrap .client-wrap span {color: #fff; vertical-align: middle; font-weight: bold;}
.manageinfo-wrap .client-wrap [class^=MuiSelect-select] {padding: 3px 20px 5px 5px; font-size: 13px; text-align: left; line-height: 1.6;}
.manageinfo-wrap .client-wrap .textField {margin-left: 0; margin-right: 0; width: 100%; margin-top: 8px; margin-bottom: 0; background: #fff;}
.manageinfo-wrap .search-result .TSCellBase span {color: rgba(0,0,0,.4) !important; font-size: .8em !important;}
.manageinfo-wrap .search-result * {line-height: 1.2 !important;}
.manageinfo-wrap .search-result .TSClassPreview > div {padding: 10px 0;}
.manageinfo-wrap .search-result .TSToolbarRow {background: #858585; border-top: 2px solid #c8c8c8;}
.manageinfo-wrap .search-result .TSToolbarRow td {color: #fff;}
.manageinfo-wrap .MuiInputBase-input-1229 {font-size: .9em;  text-align: left; padding: 6px 5px 7px;}

.manageinfo-wrap .portlet.light.bordered {border: none; padding: 0; border-left: none !important; padding-left: 0; margin-bottom: 0;}

.manageinfo-wrap .search-result .TSHeadLeft, .manageinfo-wrap .search-result .TSHeadMid, .manageinfo-wrap .search-result .TSHeadRight {border-top: 1px solid transparent;}
.manageinfo-wrap .search-result .TSHeadMid, .manageinfo-wrap .search-result .TSBodyMid, .manageinfo-wrap .search-result .TSFootMid {border-left: 1px solid transparent; border-right: 1px solid transparent;}
.search-result {height: calc( 100% - 85px ); width: 100%;}
.search-result #C101010_01 {height: 100% !important; width: 100% !important;}
.search-result .TSNoDataRow {border-top: none !important;}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {padding: 0 10px;}

.manageinfo-wrap .content-wrap {margin: 0; padding: 20px; height: calc( 100% - 32px );}
.manageinfo-wrap .summary_bg3 {background-color: #eceff4;}
.manageinfo-wrap .summary_box2 {float: left; border: none; border-radius: 2px; padding: 7px; margin-left: 0; margin-right: 0; color: #2b3b54; width: 100%; text-align: center; font-weight: 200; font-size: 16px;}
.manageinfo-wrap .summary_box2 strong {margin-right: 10px;}

.manageinfo-wrap .summary_bo_box {background: #fff; border: 1px solid #dddddd; height: 110px; padding: 10px 20px; float: left; width: 25%; position: relative; margin-right: -1px;}
.manageinfo-wrap .summary_bo_box.bg2 {background: #f3f7ff;}
.manageinfo-wrap .summary_bo_box ul {padding: 0; text-align: left;}
.manageinfo-wrap .summary_bo_box .s_cir {background: #bdbdbd; color: #fff; border-radius: 20px; width: 20px; height: 20px; line-height: 17px; text-align: center; font-size: 20px; font-weight: 800; position: absolute; top: calc( 50% - 10px ); right: -10px; z-index: 10;}
.manageinfo-wrap .summary_bo_box .s_cir_red {background: #c33819; color: #fff; border-radius: 20px; width: 20px; height: 20px; line-height: 17px; text-align: center; font-size: 20px; font-weight: 800; position: absolute; top: calc( 50% - 10px ); right: -10px; z-index: 10;}
.manageinfo-wrap .summary_bo_box .snum {color: #46495c; font-size: 26px; font-weight: 600; list-style: none; text-align: left; line-height: 1.3;}
.manageinfo-wrap .summary_bo_box .snum .t2 {color: #456eae; font-size: 17px; font-weight: bold; margin: 0 0 10px; display: block;}
.manageinfo-wrap .summary_bo_box .stit {background: #e0ebff; color: #456eae; font-size: 12px; border-radius: 10px; height: 20px; line-height: 18px; display: inline-block; padding: 0 10px; list-style: none; text-align: left;}
.manageinfo-wrap .summary_bo_box .stit span {margin-left: 5px;}

.tbl-typeA {border-collapse: collapse; padding: 0; margin: 0; border: 1px solid #d2d2d2; width: 100%; background: #fff; font-size: 12px;}
.tbl-typeA th {padding: 6px 10px; font-weight: 600; border: 1px solid #d2d2d2; line-height: 1.2; text-align: left; vertical-align: middle; color: #333333; background: #f3f3f3; white-space: nowrap;}
.tbl-typeA td {padding: 5px 10px; font-weight: 300;  border: 1px solid #d2d2d2; line-height: 1.2; text-align: center; vertical-align: middle; color: #333333; word-break: keep-all;}
.tbl-typeA tr:hover {background: #fafafa;}
.tbl-typeA a {color: #333333;}
.tbl-typeA .bg-yellow {background: #feffa8 !important;}

.manageinfo-wrap .summary_box3 {float: left; border: none; border-radius: 4px; padding: 10px 0; margin-right: 0; color: #000000;}
.manageinfo-wrap .row {margin-right: 0; margin-left: 0;}
.manageinfo-wrap .center-wrap {padding-left: 0 !important; border-right: 1px solid #ccd6e8;}
.manageinfo-wrap .side-wrap {padding-right: 0 !important;}
.manageinfo-wrap .center-wrap .TSCellHeader, .manageinfo-wrap .side-wrap .TSCellHeader {background-color: #f3f3f3; color: #000; font-size: 12px !important; line-height: 1 !important; padding: 3px 5px !important; border-right: 1px solid #ddd;}
.manageinfo-wrap .center-wrap .TSHeadLeft, .manageinfo-wrap .center-wrap .TSHeadMid, .manageinfo-wrap .center-wrap .TSHeadRight,
.manageinfo-wrap .side-wrap .TSHeadLeft, .manageinfo-wrap .side-wrap .TSHeadMid, .manageinfo-wrap .side-wrap .TSHeadRight {border-bottom: 1px solid #ddd; border-top: 1px solid #ddd;}
.manageinfo-wrap .center-wrap .TSNoDataRow, .manageinfo-wrap .side-wrap .TSNoDataRow {border-top: 0 !important;}
.manageinfo-wrap .TSRowSpace4.TSRowSpaceFirst .TSSolidRow {border-top: 1px solid #d2d2d2;}
.manageinfo-wrap .TSRowSpace0 .TSSolidRow, .manageinfo-wrap .TSRowSpace1 .TSSolidRow, .manageinfo-wrap .TSRowSpace2 .TSSolidRow, .manageinfo-wrap .TSRowSpace3 .TSSolidRow {border-left: 1px solid #d2d2d2; border-right: 1px solid #d2d2d2;}

.manageinfo-wrap .bl {margin: 6px 5px 10px 0; width: 12px;}

/* BOM & BOP */
.bom-wrap {width: 100%; height: 100%; padding: 10px; overflow: auto; box-sizing: border-box;}
.bom-wrap div[class^=depth] {position: relative;}
.bom-wrap div[class^=depth]::before {width: 25px; height: 55px; content: ''; position: absolute; top: -45px; left: -24px; border-left: 3px solid #cacaca; border-bottom: 3px solid #cacaca;}
.bom-wrap div.depth1[class^=depth]::before {display: none;}
.bom-wrap .depth1 {margin-left: 0;}
.bom-wrap .depth2 {margin-left: 30px;}	
.bom-wrap .depth3 {margin-left: 60px;}
.bom-wrap .depth4 {margin-left: 90px;}
.bom-wrap .depth5 {margin-left: 120px;}
.bom-wrap .depth6 {margin-left: 150px;}
.bom-wrap .depth7 {margin-left: 180px;}
.bom-wrap .depth8 {margin-left: 210px;}
.bom-wrap .depth9 {margin-left: 240px;}
.bom-wrap .depth10 {margin-left: 270px;}
.bom-wrap .depth11 {margin-left: 300px;}
.bom-wrap .depth12 {margin-left: 330px;}
.bom-wrap .btn-tree {display: inline-block; padding: 0; margin-right: 8px; width: 12px; height: 12px; background-color: transparent; background-repeat: no-repeat; background-size: 100% 100%; position: relative; z-index: 2; border: 2px solid #fff; box-sizing: content-box; cursor: pointer; outline: none;}
.bom-wrap .btn-tree.btn-minus {background-image: url("/assets/images/etc/icon_bom_minus.svg");}
.bom-wrap .btn-tree.btn-plus {background-image: url("/assets/images/etc/icon_bom_plus.svg");}
.bom-wrap a[class^=type] {text-decoration: none; font-weight: bold; display: inline-block; vertical-align: top; background-color: transparent; background-repeat: no-repeat; background-size: 20px 20px; background-position: 10px 11px; line-height: 1.2; padding: 10px 10px 7px 38px; margin: -12px 0 10px -8px; font-size: 16px; border: 1px solid transparent; box-sizing: border-box; border-radius: 3px;}
.bom-wrap a[class^=type]:hover {background-color: #f2f8fe; border: 1px solid #e7f0f9; text-decoration: none;}
.bom-wrap a[class^=type]:active, .bom-wrap a[class^=type].active {background-color: #deedff; border: 1px solid #cadef7;}
.bom-wrap a.type1 {background-image: url("/assets/images/etc/icon_bom_type1.svg"); color: #000;}
.bom-wrap a.type2 {background-image: url("/assets/images/etc/icon_bom_type2.svg"); color: #f2711c;}
.bom-wrap a.type3 {background-image: url("/assets/images/etc/icon_bom_type3.svg"); color: #387dc1;}
.bom-wrap a.type4 {background-image: url("/assets/images/etc/icon_bom_type4.svg"); color: #000;}
.bom-wrap .stxt {color: #999; font-size: 11px; font-weight: 500;}

/* 대시보드 추가 */
.dashboard-wrap .summary-wrap .summary-box .fs2 {font-size: 1em; font-weight: 300; opacity: .6;}
.dashboard-wrap .summary-wrap .summary-box .fs3 {font-size: 1em; font-weight: 300; opacity: .4;}
.goalnum {background: #fff; border-radius: 1em; height: 1.5em; line-height: 1.5em; padding: 0 .5em; margin: .3em 0 0 1em; display: inline-block; color: #000;}
.tbl-ing {width: 100%;}
.tbl-ing td {padding: 1em; background: #f6f6f6;}
.tbl-ing tr.ing td {background: #e3f5d0; color: #000;}
.nonoperitem {padding-bottom: .5em; position: relative;}
.nonoperitem::after {content: ''; width: 1.2em; height: 1.2em; border-radius: 1em; border: 1px solid #fff; display: inline-block; position: absolute; right: .86em; top: .86em; box-sizing: border-box;}
.nonoperitem dl {display: flex; font-size: 1.1em; color: #fff; padding: .6em; border-radius: 3px; margin: 0;}
.nonoperitem dt {padding: 0 1em; width: 50%;}
.nonoperitem dd {width: 30%; text-align: right;}
.nonoperitem .state1 {background: #57b92c;}
.nonoperitem .state2 {background: #9e9d9d;}
.nonoperitem .state3 {background: #d93f1f;}
.nonoperitem .state4 {background: #ff8a00;}

/* bom 추가 */ 
.bom_borderd { border: 1px solid #ddd !important;}

/* 104060 공정불량관리 그리드 스타일 */
#C104060_01 .TSMain, #C104060_01 .TSMain *,
#C104060_02 .TSMain, #C104060_02 .TSMain *,
#C104060_03 .TSMain, #C104060_03 .TSMain *,
#C104060_04 .TSMain, #C104060_04 .TSMain *,
#C104060_05 .TSMain, #C104060_05 .TSMain *,
#C104060_06 .TSMain, #C104060_06 .TSMain *,
#C104060_07 .TSMain, #C104060_07 .TSMain *,
#C104060_08 .TSMain, #C104060_08 .TSMain *,
#C104060_09 .TSMain, #C104060_09 .TSMain *,
#C104060_10 .TSMain, #C104060_10 .TSMain *
{
    font-size:12px !important; 
    line-height:20px !important
}

#C104060_01 .TSType,
#C104060_02 .TSType,
#C104060_03 .TSType,
#C104060_04 .TSType,
#C104060_05 .TSType,
#C104060_06 .TSType,
#C104060_07 .TSType,
#C104060_08 .TSType,
#C104060_09 .TSType,
#C104060_10 .TSType {
    padding: 4px 8px 4px 8px;
    /* padding: 7px 10px 6px 10px; */
}
/* for C100010 3D 화면 */
.video_detail .video-react {
    padding-top: 0px !important;
    margin-right: 800px;
}

.video_detail2 .video-react {
    padding-top: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}


.customized-scrollbar::-webkit-scrollbar {
    width: 25px !important;
}

.maxH400px{
    max-height: 400px !important;
}

.textDotHideCss {
    width: 11.5vw; 
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break:break-all
}

/* POP 그리드 스타일 */
#C103020_01 .TSMain *,
#C103020_002_01 .TSMain *,
#P_C0302000_0323 .TSMain *,
#P_C0302000_0301 .TSMain *,
#P_C103020_207_01 .TSMain *,
#P_C103020_207_02 .TSMain *,
#P_C0302000_0302_02 .TSMain *,
#P_C0302000_0302 .TSMain *,
#P_C0302000_0317 .TSMain *,
#P_C0302000_0304_1 .TSMain *,
#P_C0302000_0304_2 .TSMain *,
#P_C0302000_0310 .TSMain *,
#P_C0302000_0302_1 .TSMain *,
#C103070 .TSMain *,
#C107070_02_01 .TSMain *
{
    font-size:14px !important; 
    line-height:22px !important;
}

#C103020_01 .TSType,
#C103020_002_01 .TSType,
/* #P_C0302000_0323 .TSType, */
#P_C0302000_0301 .TSType,
#P_C103020_207_01 .TSType,
#P_C103020_207_02 .TSType,
#P_C0302000_0302_02 .TSType,
#P_C0302000_0302 .TSType,
#P_C0302000_0317 .TSType,
#P_C0302000_0304_1 .TSType,
#P_C0302000_0304_2 .TSType,
#P_C0302000_0310 .TSType,
#P_C0302000_0302_1 .TSType,
#C103070 .TSType,
#C107070_02_01 .TSType
{
    padding:7px 10px 6px 10px;
    white-space:nowrap;
}

.HKC-TextField{
    font-size: 1.4rem;
}

.HKC-Select{
    font-size: 1.4rem;
    margin-top: 18px;
}

.t_left {text-align: left;}
.t_center {text-align: center;}
.t_right {text-align: right;}

.btn_JidokaCall {
    justify-content: center !important;
}

.no-scroll::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

.slimscroll::-webkit-scrollbar {
    height: 8px;
}

.HKC_mes_jidoka_call_popup {
    height: 625px !important;
}

/* 7.25PHW 공정흐름도에 필요한 CSS C111010 */


.roundedBoxText_C111010{
    margin-top:15px;
}

.roundedBoxText_C111030line2{
    margin-top:3px;
}

.roundedBoxPink_C111010{
    float: left;
    width: 120px;
    height: 50px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #BF360C;
    border-radius: 10px;
}

.roundedBoxGrey_C111010{
    float: left;
    width: 120px;
    height: 50px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #808080;
    border-radius: 10px;
}

.line {
    border-left: thick solid black; 
    height: 100%;
}

.linehorizon {
    border-top: thick solid black; 
    height: 100%;
}

.lineL {
    border-left: thick solid black; 
    border-bottom: thick solid black; 
    height: 100%;
    width: 100%;
}

.linereverseL {
    border-right: thick solid black; 
    border-bottom: thick solid black; 
    height: 100%;
    width: 100%;
}

.lineㄱ {
    border-right: thick solid black; 
    border-top: thick solid black; 
    height: 100%;
    width: 100%;
}

.linereverseㄱ {
    border-left: thick solid black; 
    border-top: thick solid black; 
    height: 100%;
    width: 100%;
}

.textline {
    text-decoration: underline;
 }

.roundedBoxcoworker{
    float: left;
    width: 120px;
    height: 50px;
    font-size: 15px;
    /* color: #fff; */
    color: black;
    text-align: center;
    background: #f2dcdb;
    border-radius: 10px;
}

.POPTEXTRED{
    font-size: 25px;
    color: red;
    text-align: center;
    font-weight: bold;
}

.POPTEXT{
    text-align: left;
    font-size: 25px;
    text-align: center;
    font-weight: bold;
}

.SCMTEXT{
    text-align: left;
    font-size: 17px;
    text-align: center;
    font-weight: bold;
}

/* C111030 7.27 텍스트 두줄보내기는 애매한 긴박스 생성 end*/

.roundedBoxPink_C111010_1{
    float: left;
    width: 150px;
    height: 50px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #BF360C;
    border-radius: 10px;
}

.roundedBoxGrey_C111010_1{
    float: left;
    width: 150px;
    height: 50px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #808080;
    border-radius: 10px;
}

.roundedBoxPinkBOTTOM_C111010{
    float: left;
    width: 150px;
    height: 40px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #BF360C;
    border-radius: 10px;
}

.roundedBoxGreyBOTTOM_C111010{
    float: left;
    width: 150px;
    height: 40px;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #808080;
    border-radius: 10px;
}

.roundedBoxText_C111010BOTTOM{
    margin-top:8px;
}

/* C111010 박현우 end*/