.flex-half {
    flex: 0 45%;
    margin: 0.4rem;
}

.hidden-scrollbar {
    -ms-overflow-style: none;
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}
