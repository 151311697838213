@import 'normalize.css';

* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: 'NanumGothic', Muli, Roboto, Helvetica Neue, Arial, sans-serif !important;
    background-color: #262933;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

h1, .h1 {
    font-size: 24px;
}

h2, .h2 {
    font-size: 20px;
}

h3, .h3 {
    font-size: 16px;
}

h4, .h4 {
    font-size: 15px;
}

h5, .h5 {
    font-size: 13px;
}

h6, .h6 {
    font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

/*** Calendar ***/
.Calendar {
    max-width: 100%; 
    min-width: 60rem;
    color: #333;
    font-weight: 700;
}

.Calendar header{
    position: relative;
    overflow: hidden;
    padding: 1rem 2rem;
    color: #333;
}
  
.Calendar .nowView{
    display: contents;
    width: 100px;
    float: left;
}

.Calendar nav{
    margin-top: -30px;
    text-align: center;
}

.Calendar .btn{
    box-sizing: content-box;
    width: 1rem;
    height: 2.8rem;
    line-height: 2.8rem;
    background: none;
    /* border: 1px solid #333; */
    color: #333;
    border-radius: .5rem;
    font-size: 2.5rem;
    font-family: 'Nanum Gothic', sans-serif;
    outline: none;
    cursor: pointer;
    font-weight: 900;
}

.Calendar .btn:active{
    background: rgba(94, 94, 94, 0.466);
    color: #fff;
    /* border: 1px solid rgba(94, 94, 94, 0); */
}

.Calendar .todayBtn{
    width: 6rem;
}

.Calendar .main {
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    width: 100%;
    /* height: 48rem; */
    box-sizing: content-box;
}
.Calendar .days{
    border-top: 1px solid #333;
    border-left: 1px solid #333;
    text-align: center;
    width: 100%;
    height: 3rem;
    box-sizing: content-box;
}

.Calendar .day{
    border-right: 1px solid #333;
    display: inline-block;
    width: calc(100% / 7);
    text-align: center;
    padding: .4rem .8rem;
    font-size: 1.7rem;
}

.Calendar .dates {
    border-bottom: 1px solid #333;
    border-right: 1px solid #333;
    box-sizing: border-box;
    display: inline-block;
    width: calc(100% / 7);
    height: 85px;
    /* padding: .6rem .8rem; */
    font-size: 1.7rem;
    line-height: 2.5rem;
    text-align: center;
}

.Calendar .day:nth-child(7n + 1), .dates:nth-child(7n + 1){
    color: rgb(209, 35, 35);
}

.Calendar .day:nth-child(7n + 7), .dates:nth-child(7n + 7){
    color: rgb(35, 112, 212);
}

.Calendar .dates.others{
    color: rgba(200, 200, 200, 0.4);
}

.Calendar b{
    font-weight: inherit;
}

.Calendar .today > b,
.Calendar .today > strong {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    background: rgb(35, 112, 212);
    color: #fff;
    text-align: center;
}

.Calendar .dates > p{
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10rem;
    background: rgb(0, 176, 80);
    color: #fff;
    text-align: center;
    margin-top: 6px !important;
}

.Calendar .dates > div p{
    display: inline-block;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10rem;
    background: rgb(0, 176, 80);
    color: #fff;
    text-align: center;
    margin-top: 6px !important;
}

.Calendar .dates .seqs {
    background: rgb(255, 0, 0);
}

.Calendar .dates div .seqs {
    margin-right: 10px;
}

.Calendar .click{
    background: rgba(255, 253, 109, 0.3);
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

.double-bottom-border {
    border-bottom: 4px double #CDD8E4;
}

@import '../@bootstrap/css/bootstrap.css';

@import 'owp.css';

@import 'print.css';

@import 'tables.css';

@import 'prism.css';

@import 'custom.css';

@import 'components.css';

@import 'fuse-helpers.css';


